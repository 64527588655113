@import '../color-variables.scss';

.content-game {
  display: flex;
  gap: 8px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
.hud-button {
  position: absolute; /* Định vị tuyệt đối */
  top: 10px;          /* Cách đỉnh của cửa sổ 10px */
  right: 40px;        /* Cách cạnh phải của cửa sổ 10px */
  z-index: 1000;      /* Đảm bảo nút xuất hiện trên cùng */
}
.hud-button2 {
  position: absolute; /* Định vị tuyệt đối */
  top: 10px;          /* Cách đỉnh của cửa sổ 10px */
  right: 140px;        /* Cách cạnh phải của cửa sổ 10px */
  z-index: 1000;      /* Đảm bảo nút xuất hiện trên cùng */
}

.hud-button2 button {
  padding: 6px 14px; /* Tùy chọn: Căn chỉnh kích thước và khoảng cách */
  background-color: #f04040; /* Tùy chọn: Đặt màu nền */
  border: none;       /* Tùy chọn: Loại bỏ đường viền */
  border-radius: 5px; /* Tùy chọn: Góc bo tròn */
  color: rgb(255, 255, 255);       /* Tùy chọn: Màu chữ */
  font-size: 16px;    /* Tùy chọn: Kích thước chữ */
  cursor: pointer;    /* Tùy chọn: Con trỏ khi hover */
}
.hud-button button {
  padding: 6px 14px; /* Tùy chọn: Căn chỉnh kích thước và khoảng cách */
  background-color: #f04040; /* Tùy chọn: Đặt màu nền */
  border: none;       /* Tùy chọn: Loại bỏ đường viền */
  border-radius: 5px; /* Tùy chọn: Góc bo tròn */
  color: rgb(255, 255, 255);       /* Tùy chọn: Màu chữ */
  font-size: 16px;    /* Tùy chọn: Kích thước chữ */
  cursor: pointer;    /* Tùy chọn: Con trỏ khi hover */
}

.hud-button button:hover {
  background-color: #e0b030; /* Tùy chọn: Màu nền khi hover */
}

.side-bar {
  width: 125px;
  height: auto;
  padding: 8px;
  border-radius: 8px;
  background: #130900;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    width: calc(100% - 16px);
    flex-direction: row;
    align-items: center;
  }

  &__tab {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      flex-direction: row;
    }

    @media screen and (max-width: 600px) {
      width: 73%;
    }
  }

  &__tab-item {
    padding: 16px 8px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    position: relative;

    @media screen and (max-width: 1200px) {
      width: 165px;
    }

    @media screen and (max-width: 600px) {
      padding: 8px;
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    &.active,
    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }

    &.active {
      svg {
        display: block;
      }
    }

    svg {
      position: absolute;
      right: 8px;
      top: 8px;
      display: none;
    }
  }

  &__btn {
    padding: 9px 14.622px 9px 14.928px;
    color: #fff;
    text-align: center;
    font-family: 'RetroGaming', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    background: $Pink-500;

    @media screen and (max-width: 1200px) {
      display: flex;
      align-items: center;
      height: 40px;
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      padding-right: 4px;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}
.not_show {
  width: 0%;
  height: 0%;
}
.arcade-page__content{
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.box-game {
  margin: 0 auto;
   height: 100vh;
   max-width: 65vh;
   min-width: 50vh;
   width: 100%;
  // width: 420px;
  // aspect-ratio: 1/2;
  position: relative;
  background: $Blue-800;
  overflow: hidden;

  @media screen and (max-width: 460px) {
    width: 100vh;
    // aspect-ratio: 1/2;
  }

  .loading-overlay {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    background: url('../../assets/images/bg-scene.jpg');
    background-size: contain;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    color: $Neutral-Blue-25;

    background-repeat: round !important;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      img {
        max-width: 300px;
        width: 40%;
      }
    }

    &__process {
      height: 20px;
      width: 60%;
      background-color: $Neutral-Blue-25;
      border-radius: 10px;
      margin-bottom: 50px;
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        height: 10px;
        margin-bottom: 20px;
        margin-top: 5px;
      }

      &>div {
        height: 100%;
        border-radius: 10px;
        background-color: $Orange-500;
      }
    }
  }

  &__conent-game {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 0px;
  }
}

.border-game {
  border-style: solid;
  border-width: 3px 0;
  box-shadow: -0.375em 0 0 -0.125em, 0.375em 0 0 -0.125em;
  position: relative;
  z-index: 1;
  margin: 0 8px;

  &:before {
    background-color: inherit;
    border: inherit;
    border-width: 0 3px;
    bottom: 0.375em;
    content: '';
    left: -0.5em;
    pointer-events: none;
    position: absolute;
    top: 0.375em;
    right: -0.5em;
    z-index: -1;
  }

  &:after {
    background-color: inherit;
    border: inherit;
    border-width: 0 3px;
    bottom: 3px;
    content: '';
    left: -0.375em;
    position: absolute;
    top: 0.125em;
    right: -0.375em;
    z-index: -2;
  }
}

.leader-board {
  background: $Blue-800;
  padding: 11px 5px;
  width: calc(100% - 960px);
  max-height: 534px;

  @media screen and (max-width: 1200px) {
    width: inherit;
  }

  &__title {
    color: #93ff6e;
    text-align: center;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    background: $Blue-700;
  }

  &__border-top,
  &__border-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .leader-board__border {
      height: 3px;
      background: $Blue-700;

      &:first-child {
        width: calc(100% - 18px);
      }
      &:nth-child(2) {
        width: calc(100% - 12px);
      }

      &:last-child {
        width: calc(100% - 6px);
      }
    }
  }

  &__border-bottom {
    .leader-board__border {
      &:first-child {
        width: calc(100% - 6px);
      }
      &:nth-child(2) {
        width: calc(100% - 12px);
      }

      &:last-child {
        width: calc(100% - 18px);
      }
    }
  }

  .table__th {
    div {
      padding: 8px 11px;
      border-bottom: 1px solid $Overlay-W-5;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #72d84e;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      height: 20px;
    }
  }

  .table__content {
    display: flex;
    border-bottom: 1px solid $Overlay-W-5;

    &:last-child {
      border-bottom: 0;
    }

    & > div {
      display: flex;
      align-items: center;
      height: 29px;
      padding: 8px 12px;
      overflow: hidden;
      color: #72d84e;
      text-overflow: ellipsis;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: flex;

      &:first-child {
        justify-content: flex-start;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:last-child {
      div {
        border-bottom: 0;
      }
    }
  }
}

.battle {
  margin-top: 16px;
  background-color: $Blue-800;
  padding-top: 12px;
  padding-bottom: 12px;

  &__content {
    background-color: $Blue-800;
    padding: 0 16px;
  }

  &__tab {
    margin-bottom: 16px;
    align-items: center;
    position: relative;
    z-index: 2;
    width: max-content;
  }

  &__tab-item {
    cursor: pointer;
  }

  &__tab-content {
    background-color: $Neutral-Blue-900;
    padding: 0 12px;
    display: flex;
    gap: 8px;
  }

  &__tab-item-border-top {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: -1;

    .battle__border {
      height: 3px;
      background-color: $Overlay-15;

      &:first-child {
        width: calc(100% - 18px);
      }

      &:nth-child(2) {
        width: calc(100% - 12px);
      }

      &:last-child {
        width: calc(100% - 6px);
      }
    }
  }

  &__tab-border-top {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: -1;

    .battle__border {
      height: 3px;
      background-color: $Neutral-Blue-900;

      &:first-child {
        width: calc(100% - 18px);
      }

      &:nth-child(2) {
        width: calc(100% - 12px);
      }

      &:last-child {
        width: calc(100% - 6px);
      }
    }
  }

  &__tab-border-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .battle__border {
      height: 3px;
      background-color: $Neutral-Blue-900;

      &:first-child {
        width: calc(100% - 6px);
      }

      &:nth-child(2) {
        width: calc(100% - 12px);
      }

      &:last-child {
        width: calc(100% - 18px);
      }
    }
  }

  &__tab-item-border-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .battle__border {
      height: 3px;

      &:first-child {
        width: calc(100% - 6px);
        background-color: $Overlay-15;
      }

      &:nth-child(2) {
        width: calc(100% - 12px);
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:last-child {
        width: calc(100% - 18px);
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__border-right {
    img {
      transform: rotate(180deg);
    }
  }

  &__tab-item-not-active {
    padding: 0 12px;
    color: $Neutral-Blue-25;
    text-align: center;
    /* Body Medium/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__tab-item-content {
    background: $Overlay-15;
    padding: 0 12px;
    color: $Neutral-Blue-25;
    text-align: center;
    /* Body Medium/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .table__th {
    border-bottom: 1px solid $Overlay-W-5;
    display: flex;

    div {
      padding: 12px 16px;
      color: $Neutral-0;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .table__content {
    display: flex;
    border-bottom: 1px solid $Overlay-W-5;

    div {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      height: 40px;
      color: $Neutral-0;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &__player {
    border-bottom: 1px solid $Overlay-W-5;
    padding-bottom: 8px;
  }

  &__item {
    display: flex;
    gap: 8px;
    margin-bottom: 2px;
    align-items: center;

    & .date {
      padding: 0;
    }

    & > div {
      padding: 12px 0;

      &:first-child {
        width: 128px;
        color: $Neutral-0;
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &:last-child {
        overflow: hidden;
        color: var(--Neutral-0, #fffdf1);
        text-overflow: ellipsis;
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.title {
  z-index: 3;
  position: relative;
  margin-left: 200px;
  display: flex;

  img {
    max-width: 670px;
  }

  @media screen and (max-width: 1300px) {
    margin-left: inherit;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
}

.arcade-center {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin: 36px 0 16px 0;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin: 24px 0 16px 0;
    align-items: center;

    &__list-btn {
      width: 100%;
      display: flex;
    }
  }

  &__list-btn {
    display: flex;
    gap: 8px;

    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }

  &__btn {
    min-width: 102px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $Neutral-Blue-900;
    text-align: center;
    font-family: 'RetroGaming', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    padding: 10px 13px;
    gap: 4px;
    border-radius: 8px;
    background: $Neutral-Blue-25;
    cursor: pointer;
  }

  &__btn-ring {
    padding: 10px;
    border-radius: 8px;
    background: $Neutral-Blue-25;
    cursor: pointer;
    display: flex;
  }
}
